<template>
  <dialog-outline v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:context>
      <div v-show="visiblePrompt">
        <br />
        <base-prompt :prompt="createPrompt()"></base-prompt>
      </div>

      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk" :color="promptColorClass">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import {
  findRecordOperation,
  recordOperation
} from "@/model/record/recordModel";

// services
import {
  fullRecordName,
  getRecordTypeName
} from "@/services/record/recordService";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { promptAbleMixin } from "@/mixins/shared/promptAble/promptAbleMixin";
import { actionResultType } from "@/model/action/actionModel";
import { isPdfExtension } from "@/model/record/fileModel";

export default {
  name: "ReIndexDialog",
  mixins: [dialogOutlineMixin, promptAbleMixin],
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,
    reindexEvent: {
      type: Function,
      required: true
    }
  },

  computed: {
    /**
     * Reindex Operation
     * @return {{name: string, icon: string, label: string}} Reindex Operation
     */
    operation() {
      return findRecordOperation(recordOperation.Reindex);
    },

    /**
     * Reindex operation Label
     * @return {string} Reindex operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Reindex Dialog title
     * @return {string} Reindex Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel} ${getRecordTypeName(this.record)}`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `'${fullRecordName(
        this.record
      )}' has been added to the indexing Queue`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * Prompt text
     * remarks: overwrite promptAbleMixin.promptText
     * @return {string}
     */
    promptText() {
      return `Do you want to ${this.operationLabel} '${fullRecordName(
        this.record
      )}'?`;
    },

    /**
     * current Prompt Type
     * remarks:  overwrite promptAbleMixin.currentPromptType
     * @return {string} current Prompt Type
     */
    currentPromptType() {
      return this.promptTypeName.success;
    },

    /**
     * Prompt Hint
     * remarks:  overwrite promptAbleMixin.promptHint
     * @return {string} Prompt Hint
     */
    promptHint() {
      return isPdfExtension(this.record.extension)
        ? `Reindexing will extract text and make the ${getRecordTypeName(
            this.record
          )} searchable`
        : `Reindexing ${getRecordTypeName(this.record)} will extract text`;
    }
  },

  methods: {
    /**
     * Performs Remove Page
     */
    async onOk() {
      try {
        this.clearAlert();
        const result = await this.reindexEvent();
        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          this.internalAlert = this.createAlert(
            this.alertTypeName.error,
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.action, e),
          false
        );
      }
    },

    /**
     * Handle on Dialog Opened event in order to initialize data
     */
    onDialogOpened() {
      this.internalAlert = undefined;
    },

    /**
     * Handle on Dialog Closed event in order to clear data
     */
    onDialogClosed() {
      this.internalAlert = undefined;
    }
  }
};
</script>
